<template>
  <div class="tableList">
    <div>
      <a-popconfirm
            title="确认同步应用配置吗"
            @confirm="uploadConfig"
          >
          <a-button type="danger" style="margin:10px;">同步配置</a-button>
      </a-popconfirm>
      <a-table
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="false"
        :loading="loading"
        bordered
        size="small"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: tableInfo.tableHeight }"
      >
      <template slot="num" slot-scope="text, record, index">
          {{ parseInt(index) + 1 }}
        </template>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status==1" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="internalApp" slot-scope="internalApp">
          <a-tag v-if="internalApp==1" color="green">是</a-tag>
          <a-tag v-else color="red">否</a-tag>
        </span>
        <span slot="action" slot-scope="info">
          <a-popconfirm
            title="是否确认禁用该应用"
            @confirm="changeStatus(info.id, 0)"
            v-if="info.status == 1"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <a-button
              size="small"
              type="danger"
            >禁用</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="是否确认启用该应用"
            @confirm="changeStatus(info.id, 1)"
            v-else
          >
            <a-icon slot="icon" type="question-circle-o" />
            <a-button  size="small" >启用</a-button>
          </a-popconfirm>
         
        </span>
      </a-table>
    </div>
   
  </div>
</template>

<script>
import appApi from '@/api/app'
const columns = [
  {
    dataIndex: "num",
    align: "center",
    title: "序号",
    width: 50,
    scopedSlots: { customRender: "num" },
  },
  {
    title: "应用名称",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "应用Key",
    dataIndex: "appKey",
    align: "center",
  },
  {
    title: "应用域名",
    dataIndex: "domain",
    align: "center",
  },
  {
    title: "内部应用",
    dataIndex: "internalApp",
    align: "center",
    scopedSlots: { customRender: "internalApp" },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    width: 240,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "applist",
  data() {
    return {
      loading: false,
      
      tableInfo: {
        datas: [],
        columns,
        tableHeight: window.innerHeight - 310,
      }
    };
  },
  components: {
  },
  mounted() {
    _this = this;
  
    _this.getAll();
  },
  methods: {
    async getAll() {
      appApi.getAll().then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data;
        }
      });
    },
    uploadConfig(){
      appApi.uploadConfig().then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
           _this.$message.success(res.errorMsg);
        }else{
           _this.$message.error(res.errorMsg);
        }
      });
    },

    changeStatus(id, status) {
      _this.loading = true;
      let param = {
        id: id,
        status: status,
      };
      appApi
        .changeStatus(param)
        .then((res) => {
          if (res.errorCode === _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getAll();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.loading = false;
        })
        .finally(() => {
          _this.loading = false;
        });
    }
  },
};
</script>

<style lang="less">
</style>