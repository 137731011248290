import request from '@/request';

const part_url = "/admin/app/";
export default {
    getAll() {
        let url = part_url + "getAll";
        return request.get(url);
    },
    changeStatus(data){
        let url = part_url + "changeStatus";
        return request.post(url, {}, data);
    },
    updateMenus(id,menus){
        let url = part_url + "changeStatus";
        return request.post(url, {id}, menus);
    },
    uploadConfig(){
        let url = part_url + "uploadConfig";
        return request.get(url);
    }
}